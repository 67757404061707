<template>
    <div>
        <!--tab分页-->
        <div class="tab-switch centerBox1">
            <div @click="enterStoreList()" :class="thisPage==='1'?'hight-light':''" class="tab-item">
                <div>店铺管理</div>
                <div v-show="thisPage==='1'" class="hight-light-line"></div>
            </div>
            <div @click="enterArgumentList()" :class="thisPage==='2'?'hight-light':''" class="tab-item">
                <div>我的协议</div>
                <div v-show="thisPage==='2'" class="hight-light-line"></div>
            </div>
        </div>
        <!--列表内容展示-->
        <storeList v-show="thisPage === '1'"></storeList>
        <agreementList v-show="thisPage === '2'"></agreementList>
    </div>
</template>

<script>
    import storeList from '../storeList/index'
    import agreementList from '../agreementList/index'

    export default {
        name: "home",
        data() {
            return {
                thisPage: '1',
            }
        },
        components: {
            storeList,
            agreementList
        },
        methods: {
            enterStoreList() {
                this.thisPage = '1'
                /*this.$router.push({
                    path: "/storeManagement/storeList",
                });*/

            },
            enterArgumentList() {
                this.thisPage = '2'
                /* this.$router.push({
                     path: "/storeManagement/agreementList",
                 });*/
            },
        },
    }
</script>

<style scoped lang="scss">
    .centerBox1 {
        margin: auto;
        width: 1200px;
    }

    .tab-switch {
        display: flex;
        margin-top: 100px;
        margin-bottom: 24px;
        .tab-item {
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            color: #353535;
            line-height: 24px;
            margin-right: 48px;
        }
        .hight-light {
            color: #54AB8E !important;
        }
        .hight-light-line {
            width: 28px;
            height: 2px;
            background: #54AB8E;
            border-radius: 1px;
            margin: 10px auto;
            margin-bottom: 0;
        }
    }
</style>